import React from "react";
import { NavLink } from "react-router-dom";
import * as IconlyPack from "react-iconly";
import { useTranslation } from "react-i18next";

var NavBarCoordonnateur = () => {
  const { t } = useTranslation();
  const [activeRessource, setActiveRessource] = React.useState("");
  const [activeAnnuaire, setActiveAnnuaire] = React.useState("");
  const [activeBlog, setActiveBlog] = React.useState("");

  React.useEffect(() => {
    if (
      window.location.pathname === "/blog-coordonnateur" ||
      window.location.pathname === "/categories-coordonnateur"
    ) {
      setActiveBlog("actived");
    } else {
      setActiveBlog("");
    }

    if (
      window.location.pathname === "/documents-coordonnateur" ||
      window.location.pathname === "/guides-coordonnateur" ||
      window.location.pathname === "/catalogue-des-universites-coordonnateur"
    ) {
      setActiveRessource("actived");
    } else {
      setActiveRessource("");
    }

    if (
      window.location.pathname === "/gestion-des-fulbrighters-coordonnateur" ||
      window.location.pathname ===
        "/experts-et-personnes-ressources-coordonnateur" ||
      window.location.pathname === "/institutions-et-partenaires-coordonnateur"
    ) {
      setActiveAnnuaire("actived");
    } else {
      setActiveAnnuaire("");
    }
  }, []);

  return (
    <>
      <li className="nav-item nav-item-vertical-custom">
        <NavLink
          className="nav-link nav-link-vertival-custom"
          to="/dashboard-coordonnateur"
          activeClassName="actived"
        >
          <IconlyPack.Chart
            set="bold"
            primaryColor="white"
            className="icon-navbar-vertical"
          />
          <span className="hiddenable-navlink-label">{t("dashboard")}</span>
        </NavLink>
      </li>
      <li className="nav-item nav-item-vertical-custom">
        <a
          // className="btn nav-link nav-link-vertival-custom arrow-collapse-costom collapsed"
          className={`nav-link nav-link-vertival-custom arrow-collapse-costom collapsed ${activeRessource}`}
          href="#collapseRessources"
          data-toggle="collapse"
          data-target="#collapseRessources"
          aria-expanded="false"
          aria-controls="collapseRessources"
        >
          <IconlyPack.Wallet
            set="bold"
            primaryColor="white"
            className="icon-navbar-vertical"
          />{" "}
          <span className=" hiddenable-navlink-label">
            {t("ressource")}
            <i className="fa fa-fw fa-chevron-down"></i>
            <i className="fa fa-fw fa-chevron-right"></i>
          </span>
        </a>
        <div className="collapse" id="collapseRessources">
          <div className="card card-body padding-card">
            <ul>
              <li className="list-style">
                <NavLink
                  className="nav-link nav-link-vertival-custom padd-t-b"
                  to="/documents-coordonnateur"
                >
                  <span className="hiddenable-navlink-label">
                    {t("document")}
                  </span>
                </NavLink>{" "}
              </li>
              <li className="list-style">
                <NavLink
                  className="nav-link nav-link-vertival-custom padd-t-b"
                  to="/guides-coordonnateur"
                >
                  <span className="hiddenable-navlink-label">{t("guide")}</span>
                </NavLink>{" "}
              </li>
              <li className="list-style">
                <NavLink
                  className="nav-link nav-link-vertival-custom padd-t-b"
                  to="/catalogue-des-universites-coordonnateur"
                >
                  <span className="hiddenable-navlink-label">
                    {t("catalogue")}
                  </span>
                </NavLink>{" "}
              </li>
            </ul>
          </div>
        </div>
      </li>
      <li className="nav-item nav-item-vertical-custom">
        <a
          // className="btn nav-link nav-link-vertival-custom arrow-collapse-costom collapsed"
          className={`nav-link nav-link-vertival-custom arrow-collapse-costom collapsed ${activeAnnuaire}`}
          href="#collapseAnnuaire"
          data-toggle="collapse"
          data-target="#collapseAnnuaire"
          aria-expanded="false"
          aria-controls="collapseAnnuaire"
        >
          <IconlyPack.AddUser
            set="bold"
            primaryColor="white"
            className="icon-navbar-vertical"
          />{" "}
          <span className=" hiddenable-navlink-label">
            {t("annuaire")}
            <i className="fa fa-fw fa-chevron-down"></i>
            <i className="fa fa-fw fa-chevron-right"></i>
          </span>
        </a>
        <div className="collapse" id="collapseAnnuaire">
          <div className="card card-body padding-card">
            <ul>
              <li className="list-style">
                <NavLink
                  className="nav-link nav-link-vertival-custom padd-t-b"
                  to="/gestion-des-fulbrighters-coordonnateur"
                >
                  <span className="hiddenable-navlink-label">
                    {t("managing_fulbrighters")}
                  </span>
                </NavLink>{" "}
              </li>
              <li className="list-style">
                <NavLink
                  className="nav-link nav-link-vertival-custom padd-t-b"
                  to="/experts-et-personnes-ressources-coordonnateur"
                >
                  <span className="hiddenable-navlink-label">
                    {t("experts_resource")}
                  </span>
                </NavLink>{" "}
              </li>
              <li className="list-style">
                <NavLink
                  className="nav-link nav-link-vertival-custom padd-t-b"
                  to="/institutions-et-partenaires-coordonnateur"
                >
                  <span className="hiddenable-navlink-label">
                    {t("institution_partners")}
                  </span>
                </NavLink>{" "}
              </li>
              <li className="list-style">
                <NavLink
                  className="nav-link nav-link-vertival-custom padd-t-b"
                  to="/contacts-urgence-coordonnateur"
                >
                  <span className="hiddenable-navlink-label">
                    {t("emergency_contacts")}
                  </span>
                </NavLink>{" "}
              </li>
            </ul>
          </div>
        </div>
      </li>

      <li className="nav-item nav-item-vertical-custom">
        <NavLink
          className="nav-link nav-link-vertival-custom"
          to="/forum-coordonnateur"
          activeClassName="actived"
        >
          <IconlyPack.People
            set="bold"
            primaryColor="white"
            className="icon-navbar-vertical"
          />
          <span className="hiddenable-navlink-label">Forum</span>
        </NavLink>
      </li>
      <li className="nav-item nav-item-vertical-custom">
        <a
          // className=""
          className={`nav-link nav-link-vertival-custom arrow-collapse-costom collapsed ${activeBlog}`}
          href="#collapseBlog"
          data-toggle="collapse"
          data-target="#collapseBlog"
          aria-expanded="false"
          aria-controls="collapseBlog"
        >
          <IconlyPack.Document
            set="bold"
            primaryColor="white"
            className="icon-navbar-vertical"
          />{" "}
          <span className=" hiddenable-navlink-label">
            {t("blog")}
            <i className="fa fa-fw fa-chevron-down"></i>
            <i className="fa fa-fw fa-chevron-right"></i>
          </span>
        </a>
        <div className="collapse" id="collapseBlog">
          <div className="card card-body padding-card">
            <ul>
              <li className="list-style">
                <NavLink
                  className="nav-link nav-link-vertival-custom padd-t-b"
                  to="/blog-coordonnateur"
                >
                  <span className="hiddenable-navlink-label">
                    {t("article_management")}
                  </span>
                </NavLink>{" "}
              </li>
              <li className="list-style">
                <NavLink
                  className="nav-link nav-link-vertival-custom padd-t-b"
                  to="/categories-coordonnateur"
                >
                  <span className="hiddenable-navlink-label">
                    {t("category_management")}
                  </span>
                </NavLink>{" "}
              </li>
            </ul>
          </div>
        </div>
        {/* <NavLink
          className="nav-link nav-link-vertival-custom"
          to="/blog-coordonnateur"
          activeClassName="actived"
        >
          <IconlyPack.Document
            set="bold"
            primaryColor="white"
            className="icon-navbar-vertical"
          />
          <span className="hiddenable-navlink-label">
            Blog
            <i className="fa fa-fw fa-chevron-down"></i>
            <i className="fa fa-fw fa-chevron-right"></i>
          </span>
        </NavLink> */}
      </li>
      <li className="nav-item nav-item-vertical-custom">
        <NavLink
          className="nav-link nav-link-vertival-custom"
          to="/reports-coordonnateur"
          activeClassName="actived"
        >
          <IconlyPack.Danger
            set="bold"
            primaryColor="white"
            className="icon-navbar-vertical"
          />
          <span className="hiddenable-navlink-label">{t("reports")}</span>
        </NavLink>
      </li>
      <li className="nav-item nav-item-vertical-custom">
        <NavLink
          className="nav-link nav-link-vertival-custom"
          to="/incidents&feedbacks-coordonnateur"
          activeClassName="actived"
        >
          <IconlyPack.InfoCircle
            set="bold"
            primaryColor="white"
            className="icon-navbar-vertical"
          />
          <span className="hiddenable-navlink-label">{t("incidents")}</span>
        </NavLink>
      </li>
      <li className="nav-item nav-item-vertical-custom">
        <NavLink
          className="nav-link nav-link-vertival-custom"
          to="/messages-coordonnateur"
          activeClassName="actived"
        >
          <IconlyPack.Message
            set="bold"
            primaryColor="white"
            className="icon-navbar-vertical"
          />
          <span className="hiddenable-navlink-label">Messages</span>
        </NavLink>
      </li>
      <li className="nav-item nav-item-vertical-custom">
        <NavLink
          className="nav-link nav-link-vertival-custom"
          to="/agenda-coordonnateur"
          activeClassName="actived"
        >
          <IconlyPack.Calendar
            set="bold"
            primaryColor="white"
            className="icon-navbar-vertical"
          />
          <span className="hiddenable-navlink-label">Agenda</span>
        </NavLink>
      </li>
      <li className="nav-item nav-item-vertical-custom">
        <NavLink
          className="nav-link nav-link-vertival-custom"
          to="/profil-coordonnateur"
          activeClassName="actived"
        >
          <IconlyPack.User
            set="bold"
            primaryColor="white"
            className="icon-navbar-vertical"
          />
          <span className="hiddenable-navlink-label"> {t("my_profil")} </span>
        </NavLink>
      </li>
    </>
  );
};

export default NavBarCoordonnateur;
