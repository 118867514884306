import Skeleton from "@nejcm/react-skeleton";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as IconlyPack from "react-iconly";
import { useDispatch, useSelector } from "react-redux";
import { fetchContacts } from "../../../redux/emergency-contacts/contactsActions";
import FrontFooter from "../frontFooter";
import FrontHeader from "../frontHeader";
import "./ContactsUrgence.css";

const CardSkeleton = () => (
  <>
    {[1, 2, 3, 4, 5, 6].map((item) => (
      <div className="col-md-12 mb-3" key={item}>
        <Skeleton height={80} />
      </div>
    ))}
  </>
);

const ContactsUrgence = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const contacts = useSelector((state) => state.emergencyContacts);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  useEffect(() => {
    dispatch(fetchContacts());
  }, [dispatch]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const filteredContacts = contacts?.data?.filter(
    (contact) =>
      contact.nom?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      contact.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      contact.telephone?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredContacts?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="component-front-home">
      <div className="content-view-start">
        <div className="container-warc pb-5 pt-3">
          <div className="row align-items-center mb-4">
            <div className="col-xl-6 col-lg-6 col-md-6">
              <div className="input-group">
                <input
                  type="search"
                  className="form-control input-annuaire pl-3"
                  placeholder={t("search")}
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon-annuaire"
                  onChange={handleSearch}
                />
                <span className="input-group-text" id="basic-addon-annuaire">
                  <IconlyPack.Search set="light" primaryColor="#000" />
                </span>
              </div>
            </div>
          </div>

          <div className="contacts-urgence-container">
            {contacts?.isLoading ? (
              <CardSkeleton />
            ) : (
              <>
                {filteredContacts?.length > 0 ? (
                  <div className="table-responsive">
                    <table className="table table-striped contact-table">
                      <thead>
                        <tr>
                          <th>{t("name")}</th>
                          <th>{t("email")}</th>
                          <th>{t("phone")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentItems?.map((contact) => (
                          <tr key={contact.id} className="contact-item">
                            <td>{contact.nom}</td>
                            <td>
                              <a
                                href={`mailto:${contact.email}`}
                                className="contact-email"
                              >
                                {contact.email}
                              </a>
                            </td>
                            <td>
                              <a
                                href={`tel:${contact.telephone}`}
                                className="contact-phone"
                              >
                                {contact.telephone}
                              </a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>

                    {/* Pagination */}
                    {filteredContacts?.length > itemsPerPage && (
                      <div className="pagination-container d-flex justify-content-center mt-4">
                        <nav>
                          <ul className="pagination">
                            {Array.from({
                              length: Math.ceil(
                                filteredContacts.length / itemsPerPage
                              ),
                            }).map((_, index) => (
                              <li
                                key={index}
                                className={`page-item ${
                                  currentPage === index + 1 ? "active" : ""
                                }`}
                              >
                                <button
                                  className="page-link"
                                  onClick={() => paginate(index + 1)}
                                >
                                  {index + 1}
                                </button>
                              </li>
                            ))}
                          </ul>
                        </nav>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="alert alert-warning text-center">
                    {t("noData")}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <FrontFooter />
    </div>
  );
};

export default ContactsUrgence;
