import {
  ADD_CONTACT_SUCCESS,
  DELETE_CONTACT_SUCCESS,
  FETCH_CONTACTS_FAILURE,
  FETCH_CONTACTS_REQUEST,
  FETCH_CONTACTS_SUCCESS,
  UPDATE_CONTACT_SUCCESS,
} from "./contactsActions";

const initialState = {
  isLoading: false,
  data: [],
  error: null,
};

const contactsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CONTACTS_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case FETCH_CONTACTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        error: null,
      };
    case FETCH_CONTACTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case ADD_CONTACT_SUCCESS:
      return {
        ...state,
        data: [...state.data, action.payload],
      };
    case UPDATE_CONTACT_SUCCESS:
      return {
        ...state,
        data: state.data.map((contact) =>
          contact.id === action.payload.id ? action.payload.contact : contact
        ),
      };
    case DELETE_CONTACT_SUCCESS:
      return {
        ...state,
        data: state.data.filter((contact) => contact.id !== action.payload),
      };
    default:
      return state;
  }
};

export default contactsReducer;
