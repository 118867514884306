import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import { adminsReducer } from "./admins/adminsReducer";
import { annual_reportsReducer } from "./annual_reports/annual_reportsReducer";
import { articleReducer } from "./articles/ArticlesReducer";
import { catalogueReducer } from "./catalogues/catalogueReducer";
import { categoriesReducer } from "./categories/CategoriesReducer";
import { cguReducer } from "./cgu/cguReducer";
import { DataChartReducer } from "./chart/chartReducer";
import { conditionReducer } from "./conditions/conditionReducer";
import { configReducer } from "./config/configReducer";
import { contactReducer } from "./contact/contactReducer";
import { dashboardReducer } from "./dashboard/dashboardReducer";
import { deviseReducer } from "./devise/DeviseReducer";
import { documentReducer } from "./documents/DocumentReducer";
import contactsReducer from "./emergency-contacts/contactsReducer";
import { eventReducer } from "./events/eventsReducer";
import { expertsReducer } from "./experts/ExpertReducer";
import { faqReducer } from "./faqs/faqReducer";
import { fulbrighterReducer } from "./fulbrighters/FulbrighterReducer";
import { incidentReducer } from "./incidents/incidentsReducer";
import { mlReducer } from "./legalNotice/LegalNoticeReducer";
import { liveReducer } from "./live/liveReducer";
import { UserMessageReducer } from "./messages/MessagesReducer";
import { notificationReducer } from "./notifications/notificationReducer";
import { UserParticipationReducer } from "./participation/participationReducer";
import { partnersReducer } from "./partners/partnerReducer";
import { playlistReducer } from "./playlist/playlistReducer";
import { postReducer } from "./Posts/PostReducer";
import { reportReducer } from "./report/reportReducer";
import { report_userReducer } from "./report_user/report_userReducer";
import { slideReducer } from "./slides/SlideReducer";
import { userReducer } from "./user/userreducer";
import { UsersReducer } from "./Users/UsersReducer";
import { videoReducer } from "./video/VideoReducer";

const middlewares = [thunk];
const middlewareEnhancer = applyMiddleware(...middlewares);

const enhancers = [middlewareEnhancer];
const composedEnhancers = composeWithDevTools(...enhancers);

const store = createStore(
  combineReducers({
    user: userReducer,
    dashboard: dashboardReducer,
    posts: postReducer,
    categories: categoriesReducer,
    articles: articleReducer,
    fulbrighters: fulbrighterReducer,
    users: UsersReducer,
    userMessages: UserMessageReducer,
    documents: documentReducer,
    videos: videoReducer,
    devise: deviseReducer,
    events: eventReducer,
    lives: liveReducer,
    userNotifications: notificationReducer,
    conditions: conditionReducer,
    userParticipations: UserParticipationReducer,
    experts: expertsReducer,
    partners: partnersReducer,
    faqs: faqReducer,
    catalogues: catalogueReducer,
    contacts: contactReducer,
    legal_notices: mlReducer,
    cgus: cguReducer,
    admins: adminsReducer,
    reports: reportReducer,
    incidents: incidentReducer,
    config: configReducer,
    dataChart: DataChartReducer,
    slides: slideReducer,
    playlist: playlistReducer,
    annual_reports: annual_reportsReducer,
    reports_user: report_userReducer,
    emergencyContacts: contactsReducer,
  }),
  process.env.NODE_ENV === "development"
    ? composedEnhancers
    : compose(...enhancers)
);

export default store;
