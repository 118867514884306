import React from "react";
import { useTranslation } from "react-i18next";
import * as IconlyPack from "react-iconly";
import { NavLink } from "react-router-dom";

var NavBarAdminMobile = () => {
  const { t } = useTranslation();
  const [activeRessource, setActiveRessource] = React.useState("");
  const [activeAnnuaire, setActiveAnnuaire] = React.useState("");
  const [activeBlog, setActiveBlog] = React.useState("");

  React.useEffect(() => {
    if (
      window.location.pathname === "/blog-admin" ||
      window.location.pathname === "/categories-admin"
    ) {
      setActiveBlog("actived");
    } else {
      setActiveBlog("");
    }

    if (
      window.location.pathname === "/documents-admin" ||
      window.location.pathname === "/guides-admin" ||
      window.location.pathname === "/catalogue-des-universites-admin"
    ) {
      setActiveRessource("actived");
    } else {
      setActiveRessource("");
    }

    if (
      window.location.pathname === "/gestion-des-fulbrighters-admin" ||
      window.location.pathname === "/experts-et-personnes-ressources-admin" ||
      window.location.pathname === "/institutions-et-partenaires-admin"
    ) {
      setActiveAnnuaire("actived");
    } else {
      setActiveAnnuaire("");
    }
  }, []);
  return (
    <>
      <li>
        <NavLink
          className="mobile nav-link nav-link-vertival-custom"
          to="/dashboard-admin"
          activeClassName="actived"
        >
          <IconlyPack.Chart
            set="bold"
            primaryColor="white"
            className="icon-navbar-vertical"
          />
          <span className="mobile-navlink-label">{t("dashboard")}</span>
        </NavLink>
      </li>
      <li className="nav-item nav-item-vertical-custom">
        <a
          // class="btn nav-link nav-link-vertival-custom arrow-collapse-costom collapsed"
          className={`nav-link nav-link-vertival-custom arrow-collapse-costom collapsed ${activeRessource}`}
          type="button"
          href="#collapseRessources"
          data-toggle="collapse"
          data-target="#collapseRessources"
          aria-expanded="false"
          aria-controls="collapseRessources"
        >
          <IconlyPack.Wallet
            set="bold"
            primaryColor="white"
            className="icon-navbar-vertical"
          />{" "}
          <span className=" mobile-navlink-label">
            {t("ressource")}
            <i class="fa fa-fw fa-chevron-down"></i>
            <i class="fa fa-fw fa-chevron-right"></i>
          </span>
        </a>
        <div class="collapse" id="collapseRessources">
          <div class="card card-body padding-card">
            <ul>
              <li className="list-style">
                <NavLink
                  className="nav-link nav-link-vertival-custom"
                  to="/documents-admin"
                >
                  <span className="mobile-navlink-label"> {t("document")}</span>
                </NavLink>{" "}
              </li>
              <li className="list-style">
                <NavLink
                  className="nav-link nav-link-vertival-custom"
                  to="/guides-admin"
                >
                  <span className="mobile-navlink-label"> {t("guide")}</span>
                </NavLink>{" "}
              </li>
              <li className="list-style">
                <NavLink
                  className="nav-link nav-link-vertival-custom"
                  to="/catalogue-des-universites-admin"
                >
                  <span className="mobile-navlink-label">
                    {" "}
                    {t("catalogue")}
                  </span>
                </NavLink>{" "}
              </li>
            </ul>
          </div>
        </div>
      </li>
      <li className="nav-item nav-item-vertical-custom">
        <a
          // class="btn nav-link nav-link-vertival-custom arrow-collapse-costom collapsed"
          className={`nav-link nav-link-vertival-custom arrow-collapse-costom collapsed ${activeAnnuaire}`}
          type="button"
          data-toggle="collapse"
          href="#collapseAnnuaire"
          data-target="#collapseAnnuaire"
          aria-expanded="false"
          aria-controls="collapseAnnuaire"
        >
          <IconlyPack.AddUser
            set="bold"
            primaryColor="white"
            className="icon-navbar-vertical"
          />{" "}
          <span className=" mobile-navlink-label">
            {t("annuaire")}
            <i class="fa fa-fw fa-chevron-down"></i>
            <i class="fa fa-fw fa-chevron-right"></i>
          </span>
        </a>
        <div class="collapse" id="collapseAnnuaire">
          <div class="card card-body padding-card">
            <ul>
              <li className="list-style">
                <NavLink
                  className="nav-link nav-link-vertival-custom"
                  to="/gestion-des-fulbrighters-admin"
                >
                  <span className="mobile-navlink-label">
                    {t("managing_fulbrighters")}
                  </span>
                </NavLink>{" "}
              </li>
              <li className="list-style">
                <NavLink
                  className="nav-link nav-link-vertival-custom"
                  to="/experts-et-personnes-ressources-admin"
                >
                  <span className="mobile-navlink-label">
                    {t("experts_resource")}
                  </span>
                </NavLink>{" "}
              </li>
              <li className="list-style">
                <NavLink
                  className="nav-link nav-link-vertival-custom"
                  to="/institutions-et-partenaires-admin"
                >
                  <span className="mobile-navlink-label">
                    {t("institution_partners")}
                  </span>
                </NavLink>{" "}
              </li>
              <li className="list-style">
                <NavLink
                  className="nav-link nav-link-vertival-custom"
                  to="/contacts-urgence-admin"
                >
                  <span className="mobile-navlink-label">
                    {t("emergency_contacts")}
                  </span>
                </NavLink>{" "}
              </li>
            </ul>
          </div>
        </div>
      </li>
      <li>
        <NavLink
          className="mobile nav-link nav-link-vertival-custom"
          to="/forum-admin"
          activeClassName="actived"
        >
          <IconlyPack.Category
            set="bold"
            primaryColor="white"
            className="icon-navbar-vertical"
          />
          <span className="mobile-navlink-label">Forum</span>
        </NavLink>
      </li>
      {/* <li>
        <NavLink
          className="mobile nav-link nav-link-vertival-custom"
          to="/blog-admin"
          activeClassName="actived"
        >
          <IconlyPack.AddUser
            set="bold"
            primaryColor="white"
            className="icon-navbar-vertical"
          />
          <span className="mobile-navlink-label">Blog</span>
        </NavLink>
      </li> */}
      <li className="nav-item nav-item-vertical-custom">
        <a
          // class="btn nav-link nav-link-vertival-custom arrow-collapse-costom collapsed"
          className={`nav-link nav-link-vertival-custom arrow-collapse-costom collapsed ${activeBlog}`}
          type="button"
          data-toggle="collapse"
          data-target="#collapseBlog"
          aria-expanded="false"
          aria-controls="collapseBlog"
          href="#collapseBlog"
        >
          <IconlyPack.AddUser
            set="bold"
            primaryColor="white"
            className="icon-navbar-vertical"
          />{" "}
          <span className=" mobile-navlink-label">
            {t("blog")}
            <i class="fa fa-fw fa-chevron-down"></i>
            <i class="fa fa-fw fa-chevron-right"></i>
          </span>
        </a>
        <div class="collapse" id="collapseBlog">
          <div class="card card-body padding-card">
            <ul>
              <li className="list-style">
                <NavLink
                  className="nav-link nav-link-vertival-custom"
                  to="/blog-admin"
                >
                  <span className="mobile-navlink-label">
                    {t("article_management")}
                  </span>
                </NavLink>{" "}
              </li>
              <li className="list-style">
                <NavLink
                  className="nav-link nav-link-vertival-custom"
                  to="/categories-admin"
                >
                  <span className="mobile-navlink-label">
                    {t("category_management")}
                  </span>
                </NavLink>{" "}
              </li>
            </ul>
          </div>
        </div>
      </li>
      <li>
        <NavLink
          className="mobile nav-link nav-link-vertival-custom"
          to="/reports-admin"
          activeClassName="actived"
        >
          <IconlyPack.Danger
            set="bold"
            primaryColor="white"
            className="icon-navbar-vertical"
          />
          <span className="mobile-navlink-label">{t("reports")}</span>
        </NavLink>
      </li>
      <li>
        <NavLink
          className="mobile nav-link nav-link-vertival-custom"
          to="/incidents&feedbacks-admin"
          activeClassName="actived"
        >
          <IconlyPack.InfoCircle
            set="bold"
            primaryColor="white"
            className="icon-navbar-vertical"
          />
          <span className="mobile-navlink-label">{t("incidents")}</span>
        </NavLink>
      </li>
      <li>
        <NavLink
          className="mobile nav-link nav-link-vertival-custom"
          to="/contacts-admin"
          activeClassName="actived"
        >
          <IconlyPack.Wallet
            set="bold"
            primaryColor="white"
            className="icon-navbar-vertical"
          />
          <span className="mobile-navlink-label">Messages contacts</span>
        </NavLink>
      </li>
      <li>
        <NavLink
          className="mobile nav-link nav-link-vertival-custom"
          to="/messages-admin"
          activeClassName="actived"
        >
          <IconlyPack.Wallet
            set="bold"
            primaryColor="white"
            className="icon-navbar-vertical"
          />
          <span className="mobile-navlink-label">Messages</span>
        </NavLink>
      </li>
      <li>
        <NavLink
          className="mobile nav-link nav-link-vertival-custom"
          to="/agenda-admin"
          activeClassName="actived"
        >
          <IconlyPack.Discovery
            set="bold"
            primaryColor="white"
            className="icon-navbar-vertical"
          />
          <span className="mobile-navlink-label">Agenda</span>
        </NavLink>
      </li>
      <li>
        <NavLink
          className="mobile nav-link nav-link-vertival-custom"
          to="/profil-admin"
          activeClassName="actived"
        >
          <IconlyPack.User
            set="bold"
            primaryColor="white"
            className="icon-navbar-vertical"
          />
          <span className="mobile-navlink-label">{t("my_profil")}</span>
        </NavLink>
      </li>
      <li>
        <NavLink
          className="mobile nav-link nav-link-vertival-custom"
          to="/parametres-admin"
          activeClassName="actived"
        >
          <IconlyPack.Setting
            set="bold"
            primaryColor="white"
            className="icon-navbar-vertical"
          />
          <span className="mobile-navlink-label">{t("setting")}</span>
        </NavLink>
      </li>
    </>
  );
};

export default NavBarAdminMobile;
