import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import { updateContact } from "../../../redux/emergency-contacts/contactsActions";

const schema = yup.object().shape({
  nom: yup.string().required("Name is required"),
  email: yup
    .string()
    .email("Invalid email format")
    .required("Email is required"),
  telephone: yup.string().required("phone_number_is_required"),
});

const ModifierContactUrgence = ({ item }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [contactData, setContactData] = useState({
    nom: item.nom || "",
    email: item.email || "",
    telephone: item.telephone || "",
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setContactData({
      ...contactData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate data with Yup schema
    schema
      .validate(contactData, { abortEarly: false })
      .then(() => {
        dispatch(updateContact(item.id, contactData));
        document
          .querySelector(`#ModifierContactUrgence${item.id} .close`)
          .click();
      })
      .catch((validationErrors) => {
        const newErrors = {};
        validationErrors.inner.forEach((error) => {
          newErrors[error.path] = error.message;
        });
        setErrors(newErrors);
      });
  };

  return (
    <div
      className="modal fade"
      id={`ModifierContactUrgence${item.id}`}
      tabIndex="-1"
      role="dialog"
      aria-labelledby={`ModifierContactUrgenceLabel${item.id}`}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5
              className="modal-title"
              id={`ModifierContactUrgenceLabel${item.id}`}
            >
              {t("edit_emergency_contact")}
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="modal-body">
              <div className="form-group">
                <label htmlFor="nom">{t("name")}</label>
                <input
                  type="text"
                  className="form-control"
                  id="nom"
                  name="nom"
                  value={contactData.nom}
                  onChange={handleChange}
                  required
                />
                {errors.nom && <div className="text-danger">{errors.nom}</div>}
              </div>
              <div className="form-group">
                <label htmlFor="email">{t("email")}</label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  name="email"
                  value={contactData.email}
                  onChange={handleChange}
                  required
                />
                {errors.email && (
                  <div className="text-danger">{errors.email}</div>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="telephone">{t("phone")}</label>
                <input
                  type="tel"
                  className="form-control"
                  id="telephone"
                  name="telephone"
                  value={contactData.telephone}
                  onChange={handleChange}
                  required
                />
                {errors.telephone && (
                  <div className="text-danger">{errors.telephone}</div>
                )}
              </div>
            </div>
            <div className="modal-footer">
              <button type="submit" className="btn crud-submit-btn">
                {t("save")}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ModifierContactUrgence;
