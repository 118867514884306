import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import { addContact } from "../../../redux/emergency-contacts/contactsActions";

const schema = yup.object().shape({
  nom: yup.string().required("Name is required"),
  email: yup
    .string()
    .email("Invalid email format")
    .required("Email is required"),
  telephone: yup.string().required("Phone number is required"),
});

const AjouterContactUrgence = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] = useState(false);

  const { register, handleSubmit, reset, formState } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset();
    }
  }, [formState, reset]);

  const onSubmit = (data) => {
    setIsSuccessfullySubmitted(true);

    dispatch(addContact(data))
      .then(() => {
        setIsSuccessfullySubmitted(false);
        window.$("#AjouterContactUrgence").modal("hide");
      })
      .catch(() => {
        setIsSuccessfullySubmitted(false);
      });
  };

  return (
    <div
      className="modal fade"
      id="AjouterContactUrgence"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="AjouterContactUrgenceLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="AjouterContactUrgenceLabel">
              {t("add_emergency_contact")}
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-group">
                <label htmlFor="nom">{t("name")}*</label>
                <input
                  type="text"
                  className="form-control"
                  id="nom"
                  {...register("nom")}
                />
                {formState.errors.nom && (
                  <span className="text-danger">
                    {formState.errors.nom.message}
                  </span>
                )}
              </div>

              <div className="form-group">
                <label htmlFor="email">{t("email")}*</label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  {...register("email")}
                />
                {formState.errors.email && (
                  <span className="text-danger">
                    {formState.errors.email.message}
                  </span>
                )}
              </div>

              <div className="form-group">
                <label htmlFor="telephone">{t("phone")}*</label>
                <input
                  type="text"
                  className="form-control"
                  id="telephone"
                  {...register("telephone")}
                />
                {formState.errors.telephone && (
                  <span className="text-danger">
                    {formState.errors.telephone.message}
                  </span>
                )}
              </div>

              <div className="modal-footer">
                {isSuccessfullySubmitted ? (
                  <button
                    type="button"
                    className="btn crud-submit-btn"
                    disabled
                  >
                    <span
                      className="spinner-border spinner-border-sm mr-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    {t("en_cours")}
                  </button>
                ) : (
                  <button type="submit" className="btn crud-submit-btn w-100">
                    {t("add")}
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AjouterContactUrgence;
