import Skeleton from "@nejcm/react-skeleton";
import FeatherIcon from "feather-icons-react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as IconlyPack from "react-iconly";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import {
  deleteContact,
  fetchContacts,
} from "../../../../redux/emergency-contacts/contactsActions";
import AjouterContactUrgence from "../../../Modals/Admin/AjouterContactUrgence";
import ModifierContactUrgence from "../../../Modals/Admin/ModifierContactUrgence";
import Header from "../../../Navs/Header";
import NavBarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
import "./ContactsUrgenceAdmin.css";

const CardSkeleton = () => (
  <>
    {[1, 2, 3, 4, 5, 6].map((item) => (
      <div className="col-md-12 mb-3" key={item}>
        <Skeleton height={150} />
      </div>
    ))}
  </>
);

const ContactsUrgenceAdmin = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const contacts = useSelector((state) => state.emergencyContacts);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchContacts());
  }, [dispatch]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const filteredContacts = contacts?.data?.filter(
    (contact) =>
      contact.nom?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      contact.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      contact.telephone?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    console.log("Contacts filtrés:", filteredContacts);
    console.log("Nombre de contacts filtrés:", filteredContacts?.length);
  }, [filteredContacts]);

  // Pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredContacts?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  useEffect(() => {
    console.log("Éléments courants:", currentItems);
    console.log("Nombre d'éléments courants:", currentItems?.length);
  }, [currentItems]);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const onDelete = (item) => {
    Swal.fire({
      title: t("warning_message_contact"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("yes"),
      cancelButtonText: t("no"),
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return dispatch(deleteContact(item.id))
          .then((response) => {
            return response;
          })
          .catch((error) => {
            console.log(error);
            Swal.showValidationMessage(`Request failed: ${error}`);
          });
      },
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          icon: "success",
          title: t("success"),
          showConfirmButton: false,
          timer: 2000,
        });
      }
    });
  };

  return (
    <div className="component-dashboard-coordonnateur">
      <Header />
      <div className="row">
        <div className="nav-container no-view-mobile">
          <NavBarVerticalV2 />
        </div>
        <div
          className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header-backend px-md-3 px-0"
          id="content-position-after-header"
        >
          <div className="page-body-container">
            <div className="page-content-container">
              <div className="page-first-row-container">
                <div className="page-title-container">
                  <h1 className="page-title">{t("emergency_contacts")}</h1>
                </div>
                <div
                  className="adding-item-container linkable"
                  data-toggle="modal"
                  data-target="#AjouterContactUrgence"
                >
                  <span className="icon-container">
                    <IconlyPack.Plus set="light" primaryColor="#005d86" />
                  </span>
                  <span className="adding-item-label">
                    {t("add_emergency_contact")}
                  </span>
                </div>
              </div>

              <div className="search-container my-4">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <IconlyPack.Search set="light" primaryColor="#005d86" />
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={t("search")}
                    value={searchTerm}
                    onChange={handleSearch}
                  />
                </div>
              </div>

              <div className="parent-table-container">
                <div className="py-4">
                  {contacts?.isLoading ? (
                    <CardSkeleton />
                  ) : filteredContacts?.length === 0 ? (
                    <div className="alert alert-warning">{t("noData")}</div>
                  ) : (
                    <div className="table-responsive">
                      <table className="table table-hover">
                        <thead>
                          <tr className="text-[#005d86]">
                            <th>{t("name")}</th>
                            <th>{t("email")}</th>
                            <th>{t("phone")}</th>
                            <th className="text-center">{t("actions")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentItems?.map((contact) => (
                            <tr key={contact.id}>
                              <td>{contact.nom}</td>
                              <td>{contact.email}</td>
                              <td>{contact.telephone}</td>
                              <td className="text-center">
                                <div className="dropdown">
                                  <button
                                    className="btn action-btn dropdown-toggle"
                                    type="button"
                                    id={`dropdownMenuButton${contact.id}`}
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <FeatherIcon
                                      icon="more-vertical"
                                      size="18"
                                    />
                                  </button>
                                  <div
                                    className="dropdown-menu action-dropdown"
                                    aria-labelledby={`dropdownMenuButton${contact.id}`}
                                  >
                                    <button
                                      className="dropdown-item"
                                      data-toggle="modal"
                                      data-target={`#ModifierContactUrgence${contact.id}`}
                                    >
                                      <FeatherIcon
                                        icon="edit-2"
                                        size="14"
                                        className="mr-2"
                                      />
                                      {t("edit")}
                                    </button>
                                    <button
                                      className="dropdown-item"
                                      onClick={() => onDelete(contact)}
                                    >
                                      <FeatherIcon
                                        icon="trash-2"
                                        size="14"
                                        className="mr-2"
                                      />
                                      {t("delete")}
                                    </button>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}

                  {filteredContacts?.length > itemsPerPage && (
                    <div className="pagination-container d-flex justify-content-center mt-4">
                      <nav>
                        <ul className="pagination">
                          {Array.from({
                            length: Math.ceil(
                              filteredContacts.length / itemsPerPage
                            ),
                          }).map((_, index) => (
                            <li
                              key={index}
                              className={`page-item ${
                                currentPage === index + 1 ? "active" : ""
                              }`}
                            >
                              <button
                                className="page-link"
                                onClick={() => paginate(index + 1)}
                              >
                                {index + 1}
                              </button>
                            </li>
                          ))}
                        </ul>
                      </nav>
                    </div>
                  )}
                </div>
              </div>

              <AjouterContactUrgence />

              {contacts?.data?.map((contact) => (
                <ModifierContactUrgence key={contact.id} item={contact} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactsUrgenceAdmin;
