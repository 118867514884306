/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { User } from "react-iconly";
import { NavLink } from "react-router-dom";
import Logo from "../../../images/logo.png";
import "./frontHeader.css";
// import FeatherIcon from 'feather-icons-react';
// import { useDispatch, useSelector } from 'react-redux';
import axios from "axios";
import moment from "moment";
import { useTranslation } from "react-i18next";
import * as IconlyPack from "react-iconly";
import { useDispatch, useSelector } from "react-redux";
import baseUrl from "../../../Http/backend-base-url";
import api from "../../../Http/global-vars";
import LanguageSelect from "../../../languageSelect";
import { fetchNotifUser } from "../../../redux/notifications/notificationAction";
import NavbarMobileV2 from "../../Dashboard/navbarMobileV2/navbarMobileV2";

var FrontHeader = ({ laddingPage }) => {
  const { t } = useTranslation();

  var userType = window.sessionStorage.getItem("userType");
  var [showNotifications, setShowNotifications] = React.useState(false);
  const user = useSelector((state) => state.user);
  const isLoggedIn = laddingPage
    ? false
    : !!window.sessionStorage.getItem("userToken");
  const userNotifications = useSelector((state) => state.userNotifications);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!user?.isLoading && isLoggedIn) {
      dispatch(fetchNotifUser(user.data?.id));
    }
  }, [dispatch, user, isLoggedIn]);

  useEffect(() => {
    if (!userNotifications?.isLoading && isLoggedIn) {
      // console.log("notif", userNotifications);
    }
  }, [userNotifications, isLoggedIn]);

  const onShowAndHideNotification = () => {
    setShowNotifications(!showNotifications);
  };

  const config = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("userToken")}`,
    },
  };

  const handleRead = (id) => {
    axios
      .put(`${api}notification/${id}/`, { read: true }, config)
      .then((response) => {
        dispatch(fetchNotifUser(user.data?.id));
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const onLogout = (e) => {
    e.preventDefault();
    window.sessionStorage.clear();
    window.location = "/app/connexion";
  };

  return (
    <div className="component-front-header">
      <div className="header-scrolling-container fixed-top" id="frontHeader">
        <nav className="navbar navbar-expand-lg  container-warc navbar-light header p-0">
          <a className="navbar-brand" href={isLoggedIn ? "/app/home" : "/"}>
            <img className="logo" alt={"AEM"} src={Logo} />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarHeaderSecondary"
            aria-controls="navbarHeaderSecondary"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarHeaderSecondary">
            <ul className="navbar-nav mx-auto py-lg-0 py-4">
              {!isLoggedIn && (
                <li className="nav-item nav-african-hot-jobs px-lg-2">
                  <NavLink exact className="nav-link trans-0-2" to="/">
                    {t("home")}
                  </NavLink>
                </li>
              )}
              {isLoggedIn && (
                <>
                  <li className="nav-item nav-african-hot-jobs px-lg-2">
                    <NavLink
                      exact
                      className="nav-link trans-0-2"
                      to="/app/home"
                    >
                      {t("home")}
                    </NavLink>
                  </li>
                  <li className="nav-item nav-african-hot-jobs px-lg-2">
                    <NavLink className="nav-link trans-0-2" to="/app/blog">
                      {t("blog")}
                    </NavLink>
                  </li>
                  <li className="nav-item nav-african-hot-jobs px-lg-2">
                    <NavLink
                      className="nav-link trans-0-2"
                      to="/app/messagerie"
                    >
                      {t("messagerie")}
                    </NavLink>
                  </li>
                  <li
                    className="nav-item nav-african-hot-jobs px-lg-2"
                    id="dropdown-finance"
                  >
                    <ul>
                      <li>
                        <a href="#" className="nav-link trans-0-2">
                          {t("ressource")}{" "}
                          <span className="pl-1 icon-dropdown-menu">
                            <IconlyPack.ChevronDown set="light" />
                          </span>
                        </a>
                        <ul className="dropdown">
                          <li className="">
                            <NavLink
                              to="/app/documents"
                              className="hover-finance list-finance nav-item nav-african-hot-jobs"
                            >
                              {t("document")}
                            </NavLink>
                          </li>
                          <li className="">
                            <NavLink
                              to="/app/guides"
                              className="hover-finance list-finance nav-item nav-african-hot-jobs"
                            >
                              {t("guide")}
                            </NavLink>
                          </li>
                          <li className="">
                            <NavLink
                              to="/app/catalogue-des-universites"
                              className="hover-finance list-finance nav-item nav-african-hot-jobs"
                            >
                              {t("catalogue")}
                            </NavLink>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>

                  <li
                    className="nav-item nav-african-hot-jobs px-lg-2"
                    id="dropdown-finance"
                  >
                    <ul>
                      <li>
                        <a href="#" className="nav-link trans-0-2">
                          {t("annuaire")}{" "}
                          <span className="pl-1 icon-dropdown-menu">
                            <IconlyPack.ChevronDown set="light" />
                          </span>
                        </a>
                        <ul className="dropdown">
                          <li className="">
                            <NavLink
                              to="/app/annuaire/pills-fulbrighter"
                              className="hover-finance list-finance nav-item nav-african-hot-jobs"
                            >
                              {t("fulbrighter")}
                            </NavLink>
                          </li>
                          <li className="">
                            <NavLink
                              to="/app/annuaire/pills-experts-personnes-ressources"
                              className="hover-finance list-finance nav-item nav-african-hot-jobs"
                            >
                              {t("experts_resource")}
                            </NavLink>
                          </li>
                          <li className="">
                            <NavLink
                              to="/app/annuaire/pills-partenaires"
                              className="hover-finance list-finance nav-item nav-african-hot-jobs"
                            >
                              {t("institution_partners")}
                            </NavLink>
                          </li>
                          <li className="">
                            <NavLink
                              to="/app/annuaire/pills-contacts-urgence"
                              className="hover-finance list-finance nav-item nav-african-hot-jobs"
                            >
                              {t("emergency_contacts")}
                            </NavLink>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </>
              )}
              <li className="nav-item nav-african-hot-jobs px-lg-2">
                <NavLink className="nav-link trans-0-2" to="/app/media">
                  Media
                </NavLink>
              </li>
            </ul>
            <div className="d-flex">
              <div className="language-select">
                <div className="language-selector-mobile">
                  <LanguageSelect />
                </div>
              </div>
              {isLoggedIn && (
                <div
                  className="notification-item-container linkable mr-5"
                  onClick={() => onShowAndHideNotification()}
                >
                  <div className="notification-item-content mt-1">
                    <IconlyPack.Notification set="light" primaryColor="black" />
                    {userNotifications?.data?.length ? (
                      <span className="notification-counter"></span>
                    ) : (
                      <span className=""></span>
                    )}{" "}
                  </div>
                </div>
              )}
              {/* A afficher si l'utilisateur n'est pas connecté */}
              {/* <NavLink className="link-header-login trans-0-2 d-block" to="/login" >Connexion</NavLink> */}

              {/* A afficher si l'utilisateur est connecté */}
              {isLoggedIn && (
                <div className="dropdown connexion-dropdown">
                  <a
                    className="dropdown-toggle trans-0-2"
                    href="#"
                    role="button"
                    id="connexionDropdown"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <span className="name-user-connect">
                      {/* {user?.data?.prenom + " " + user?.data?.nom}{" "} */}
                    </span>

                    {user?.data?.id &&
                    user?.data?.avatar &&
                    !user?.data?.avatar?.includes("default.png") ? (
                      <span className="icon-img image-responsive mx-2 p-2 ">
                        <img
                          height="35"
                          width="35"
                          src={baseUrl + user?.data?.avatar}
                          alt={user?.data?.prenom + " " + user?.data?.nom}
                        />
                      </span>
                    ) : (
                      <span className="icon image-responsive mx-2 p-2 ">
                        <User set="bold" primaryColor="#003DA5" />
                      </span>
                    )}
                  </a>

                  <div
                    className="dropdown-menu"
                    aria-labelledby="connexionDropdown"
                  >
                    <NavLink
                      className="dropdown-item no-bg border-bottom"
                      to={
                        userType === "coordo"
                          ? "/profil-coordonnateur"
                          : "/app/mon-profil"
                      }
                    >
                      {t("account")}
                    </NavLink>
                    {userType === "coordo" ? (
                      <NavLink
                        className="dropdown-item no-bg border-bottom"
                        to="/dashboard-coordonnateur"
                      >
                        {t("dashboard")}
                      </NavLink>
                    ) : null}

                    <button className="dropdown-item no-bg" onClick={onLogout}>
                      {t("logout")}
                    </button>
                  </div>
                </div>
              )}

              <div className="d-md-none d-inline-block mt-4 language-selector-mobile"></div>
            </div>
          </div>

          <div className="d-md-none d-inline-block mt-4 language-selector-mobile"></div>
          {showNotifications && (
            <div
              className={"header-hiddenable-notification-section"}
              style={{ right: "0%" }}
            >
              <div className="header-hiddenable-notification-title">
                Notifications
              </div>
              <ul className="header-hiddenable-notification-ul">
                {!userNotifications?.isLoading &&
                  (userNotifications?.data?.length ? (
                    userNotifications?.data?.map((notif) => (
                      <li
                        className="header-hiddenable-notification-li"
                        key={notif?.id}
                      >
                        <NavLink
                          to={
                            notif?.notif_type === "message"
                              ? {
                                  pathname:
                                    userType === "coordo"
                                      ? "/messages-coordonnateur"
                                      : "/app/messagerie",
                                  state: { userItem: notif?.sender },
                                }
                              : notif?.notif_type === "inform"
                              ? {
                                  pathname: "/reports-coordonnateur",
                                  state: { report: notif?.data },
                                }
                              : notif?.notif_type === "inform_user_catalogue"
                              ? {
                                  pathname: "/app/catalogue-des-universites",
                                  state: {},
                                }
                              : notif?.notif_type === "inform_user_document"
                              ? {
                                  pathname: `/app/documents/${notif?.data?.id}`,
                                  state: { document: notif?.data },
                                }
                              : notif?.notif_type === "inform_user_article"
                              ? {
                                  pathname: `/app/detail-blog/${notif?.data?.id}`,
                                  state: { article: notif?.data },
                                }
                              : notif?.notif_type === "inform_incident"
                              ? {
                                  pathname:
                                    "/incidents&feedbacks-coordonnateur",
                                  state: { incident: notif?.data },
                                }
                              : "#"
                          }
                          onClick={() => handleRead(notif?.id)}
                          className="notification-item-link"
                          key={notif?.id}
                        >
                          <p>{notif?.message}</p>
                          <span>
                            {moment(notif?.created_at).format("DD/MM/YYYY")}
                          </span>
                        </NavLink>
                      </li>
                    ))
                  ) : (
                    <li className="header-hiddenable-notification-li">
                      <div className="notification-item-link">
                        <p>{t("noData")}</p>
                      </div>
                    </li>
                  ))}
              </ul>
              {/* <p
              onClick={() => showProjetSoumis()}
              className="text-md text-center font-weight-bold"
              style={{ cursor: "pointer" }}
            >
              Voir tout
            </p> */}
            </div>
          )}
          <div className="hiddenable-nav-mobile-container no-view-desktop">
            {/* Navbar Mobile */}
            <div
              className="hiddenable-nav-mobile-content"
              id="menu-mobile-admin"
            >
              <NavbarMobileV2 />
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default FrontHeader;
