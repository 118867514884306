import React from "react";
import "react-app-polyfill/ie11";
import "react-app-polyfill/ie9";
import { ClearBrowserCacheBoundary } from "react-clear-browser-cache";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import "./i18nextConf";
import "./index.css";
import store from "./redux/store";
import * as serviceWorker from "./serviceWorker";

// import ReactGA from 'react-ga';
// ReactGA.initialize('UA-184919919-1');
// ReactGA.pageview(window.location.pathname + window.location.search);

function noop() {}

if (process.env.NODE_ENV !== "development") {
  console.log = noop;
  console.warn = noop;
  console.error = noop;
}

ReactDOM.render(
  <Provider store={store}>
      <App />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
