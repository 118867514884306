import React from "react";
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
} from "react-bootstrap-table";
import InstitutionEtPartenairesTableSkeleton from "./InstitutionEtPartenairesTableSkeleton";
import * as IconlyPack from "react-iconly";
import ImgBlog from "../../../../images/NoImage.png";
import { useSelector, useDispatch } from "react-redux";
import baseUrl from "../../../../Http/backend-base-url";
import Swal from "sweetalert2";
import InstitutionsEtPartenairesModal from "../../../Modals/Frontend/InstitutionsEtPartenairesModal";
import { deleteData } from "../../../utils/fetchData";
import { fetchPartner } from "../../../../redux/partners/partnerAction";
import ModifierPartner from "../../../Modals/Coordonnateur/ModifierPartner";
import { useTranslation } from "react-i18next";

var InstitutionEtPartenairesTable = () => {
  const { t } = useTranslation();
  const partners = useSelector((state) => state.partners);
  const dispatch = useDispatch();
  // const [partenaires ] = React.useState(
  //   InstitutionEtPartenairesTableData
  // );
  // var [ setIsPageLoading] = React.useState(true);

  var handleClearButtonClick = (onClick) => {
    console.log("This is my custom function for ClearSearchButton click event");
    onClick();
  };

  // useEffect(() => {
  //   setTimeout(() => {
  //     setIsPageLoading(false);
  //   }, 5000);
  // }, [partenaires, setIsPageLoading]);

  var createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => handleClearButtonClick(onClick)}
      />
    );
  };

  const options = {
    clearSearch: true,
    clearSearchBtn: createCustomClearButton,
    noDataText: t("noData"),
  };

  const onDelete = (item) => {
    let token = window.sessionStorage.getItem("userToken");
    Swal.fire({
      title: t("warning_message_partner"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("yes"),
      cancelButtonText: t("no"),
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return deleteData(`partenaire/${item?.id}/`, token)
          .then(async (response) => {
            console.log("res", response);
            await dispatch(fetchPartner());
            return response;
          })
          .catch((error) => {
            console.log(error);
            Swal.showValidationMessage(`Request failed: ${error}`);
          });
      },
    }).then((result) => {
      if (result.isConfirmed) {
        console.log("res", result);
        Swal.fire({
          icon: "success",
          title: t("success"),
          showConfirmButton: false,
          timer: 2000,
        });
      }
    });
  };

  const photoFormatter = (cell, row) => {
    return (
      <div className="d-flex">
        <img
          style={{ width: "70px", height: "62px", borderRadius: "5px" }}
          src={
            row?.logo && !row?.logo?.includes("default.png")
              ? baseUrl + row?.logo
              : ImgBlog
          }
          alt="Profil"
        />
        <span
          className="pl-3"
          style={{
            fontWeight: 500,
            fontSize: 14,
            color: "rgba(0, 0, 0, 0.7)",
          }}
        >
          {" "}
          {cell}
        </span>
      </div>
    );
  };

  const filterTitle = (cell, row) => {
    return cell;
  };

  const actionFormatter = (cell, row) => {
    return (
      <div className="table-actions-btn-container">
        <button className="action-btn show-btn" title="Details">
          <IconlyPack.Show
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-show-icon"
            data-toggle="modal"
            data-target={`#InstitutionsEtPartenairesModal${row?.id}`}
          />
        </button>
        <button className="action-btn edit-btn" title={t("edit")}>
          <IconlyPack.Edit
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-edit-icon"
            data-toggle="modal"
            data-target={`#ModifierInstitution${row?.id}`}
          />
        </button>
        <button className="action-btn delete-btn" title={t("delete")}>
          <IconlyPack.Delete
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-delete-icon"
            onClick={() => onDelete(row)}
          />
        </button>
        <div
          className="modal fade"
          id={`InstitutionsEtPartenairesModal${row?.id}`}
          tabIndex="-1"
          aria-labelledby="InstitutionsEtPartenairesModalLabel"
          aria-hidden="true"
        >
          <InstitutionsEtPartenairesModal item={row} />
        </div>
        <div
          className="modal fade"
          id={`ModifierInstitution${row?.id}`}
          tabIndex="-1"
          aria-labelledby="ModifierInstitutionLabel"
          aria-hidden="true"
        >
          <ModifierPartner partner={row} />
        </div>
      </div>
    );
  };

  return (
    <div className="child-table-container">
      <div className="table-body-container">
        <div className="row easypm-table-row m-t-50 m-b-50">
          <div className="col-md-12 easypm-table-col">
            {partners?.isLoading && <InstitutionEtPartenairesTableSkeleton />}
            {!partners?.isLoading && (
              <div className="table-container">
                <BootstrapTable
                  data={partners?.data}
                  tableContainerClass="table-responsive"
                  striped={true}
                  hover={true}
                  condensed={true}
                  multiColumnSort={2}
                  options={options}
                  pagination
                  search={false}
                  version="4"
                  bordered={false}
                >
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1A5C8C",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    dataField="id"
                    isKey={true}
                    hidden={true}
                  >
                    ID
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1A5C8C",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    filter={{
                      type: "TextFilter",
                      style: { visibility: "hidden" },
                    }}
                    width="50"
                    dataField="id"
                  >
                    N°
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1A5C8C",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="250"
                    filter={{ type: "TextFilter" }}
                    filterValue={filterTitle}
                    dataField="nom"
                    dataFormat={(cell, row) => photoFormatter(cell, row)}
                  >
                    {t("name")}
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1A5C8C",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    filter={{
                      type: "TextFilter",
                      style: { visibility: "hidden" },
                    }}
                    width="100"
                    dataField="contact"
                  >
                    {t("phone")}
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1A5C8C",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    filter={{
                      type: "TextFilter",
                      style: { visibility: "hidden" },
                    }}
                    width="150"
                    dataField="email"
                  >
                    {t("email")}
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1A5C8C",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    filter={{
                      type: "TextFilter",
                      style: { visibility: "hidden" },
                    }}
                    width="100"
                    dataField="pays"
                    dataAlign="center"
                  >
                    {t("country")}
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1A5C8C",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    filter={{
                      type: "TextFilter",
                      style: { visibility: "hidden" },
                    }}
                    width="100"
                    dataField="id"
                    dataFormat={(cell, row) => actionFormatter(cell, row)}
                  >
                    Action
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InstitutionEtPartenairesTable;
