/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import * as IconlyPack from "react-iconly";
import "./Annuaire.css";
// import ImgBlog from "../../../images/image1.png";
// import ImgBlog2 from "../../../images/image2.png";
// import "jquery-ui-bundle";
// import "jquery-ui-bundle/jquery-ui.min.css";
import { useHistory, useParams } from "react-router-dom";
import FrontFooter from "../frontFooter/index";
import FrontHeader from "../frontHeader/index";
// import ImgPp from "../../../images/pp-post.png";
// import { isMobile } from "react-device-detect";
import NavMenuMobile from "../frontHome/NavMenuMobile";
// import $ from "jquery";
// import PhotoProfil from "../../../assets/images/photo-profil.png";
import Skeleton from "@nejcm/react-skeleton";
import CountrySelect from "react-bootstrap-country-select";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchExperts,
  searchExpert,
} from "../../../redux/experts/ExpertAction";
import {
  fetchFulbrighters,
  searchFulbrighter,
} from "../../../redux/fulbrighters/FulbrighterAction";
import {
  fetchPartner,
  searchPartner,
} from "../../../redux/partners/partnerAction";
import AnnuaireItem from "./AnnuaireItem";
import ExpertPersonnesRessources from "./ExpertPersonnesRessources";
import InstitutionEtPartenaire from "./InstitutionEtPartenaire";
// import "bootstrap/dist/css/bootstrap.css";
import moment from "moment";
import "react-bootstrap-country-select/dist/react-bootstrap-country-select.css";
import { useTranslation } from "react-i18next";
import ContactsUrgence from "./ContactsUrgence";

var Annuaire = () => {
  const { t } = useTranslation();
  const [val, setVal] = useState("");
  const [pays, setPays] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [itemToShow, setItemToShow] = useState(12);
  const [expand, setExpand] = useState(false);
  // const options = useMemo(() => countryList().getData(), []);
  const [startDate, setStartDate] = useState(null);
  const user = useSelector((state) => state.user);
  const fulbrighters = useSelector((state) => state.fulbrighters);
  const devise = useSelector((state) => state.devise);
  const experts = useSelector((state) => state.experts);
  const partners = useSelector((state) => state.partners);
  const contacts = useSelector((state) => state.contacts);
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const { idTabs } = params;

  useEffect(() => {
    dispatch(fetchFulbrighters());
    dispatch(fetchExperts());
    dispatch(fetchPartner());
  }, [dispatch]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (idTabs) {
      setPays("");
      setStartDate("");
      setSearchTerm("");
      setVal("");
      setItemToShow(12);
      //  setExpand(true);
    }
  }, [idTabs]);

  const handleChange = (e) => {
    // console.log("search item", e.target.value.length);
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    if (idTabs === "pills-fulbrighter") {
      if (searchTerm?.length || pays || startDate) {
        dispatch(
          searchFulbrighter(
            fulbrighters?.data,
            searchTerm?.toLowerCase(),
            pays,
            startDate ? moment(startDate).format("YYYY") : ""
          )
        );
      }
    } else if (idTabs === "pills-experts-personnes-ressources") {
      if (searchTerm?.length || pays) {
        // console.log("hee")
        dispatch(searchExpert(experts?.data, searchTerm?.toLowerCase(), pays));
      }
    } else if (idTabs === "pills-partenaires") {
      if (searchTerm?.length || pays) {
        dispatch(
          searchPartner(partners?.data, searchTerm?.toLowerCase(), pays)
        );
      }
    }
  }, [
    pays,
    startDate,
    searchTerm,
    idTabs,
    dispatch,
    fulbrighters.data,
    experts.data,
    partners.data,
  ]);

  const changeHandler = (value) => {
    // console.log("options", value);
    setPays(value?.name);
    setVal(value);
  };

  useEffect(() => {
    if (idTabs === "pills-fulbrighter") {
      if (itemToShow >= fulbrighters?.filteredData?.length) {
        // setItemToShow(3);
        setExpand(true);
      } else {
        // setItemToShow(itemToShow + 5);
        setExpand(false);
      }
    } else if (idTabs === "pills-experts-personnes-ressources") {
      if (itemToShow >= experts?.filteredData?.length) {
        // setItemToShow(3);
        setExpand(true);
      } else {
        // setItemToShow(itemToShow + 5);
        setExpand(false);
      }
    } else if (idTabs === "pills-partenaires") {
      if (itemToShow >= partners?.filteredData?.length) {
        // setItemToShow(3);
        setExpand(true);
      } else {
        // setItemToShow(itemToShow + 5);
        setExpand(false);
      }
    }
  }, [
    itemToShow,
    idTabs,
    fulbrighters.filteredData.length,
    experts.filteredData.length,
    partners.filteredData.length,
  ]);

  const showMore = () => {
    console.log("ddd");
    if (idTabs === "pills-fulbrighter") {
      if (itemToShow >= fulbrighters?.filteredData?.length) {
        setItemToShow(12);
        setExpand(true);
        window.scroll(0, 0);
      } else {
        setItemToShow(itemToShow + 12);
        setExpand(false);
      }
    } else if (idTabs === "pills-experts-personnes-ressources") {
      if (itemToShow >= experts?.filteredData?.length) {
        setItemToShow(12);
        setExpand(true);
        window.scroll(0, 0);
      } else {
        setItemToShow(itemToShow + 12);
        setExpand(false);
      }
    } else if (idTabs === "pills-partenaires") {
      if (itemToShow >= partners?.filteredData?.length) {
        setItemToShow(12);
        setExpand(true);
        window.scroll(0, 0);
      } else {
        setItemToShow(itemToShow + 12);
        setExpand(false);
      }
    }
  };

  const FilterBloc = () => {
    return (
      <div className="bloc-filter-right">
        <div className="w-full">
          <label className="justify-content-end pr-2">{t("filter")}</label>
        </div>
        <div className="w-100 mx-2">
          <CountrySelect
            value={val}
            onChange={changeHandler}
            searchPartner
            placeholder={t("select_country")}
            size="sm"
            className="w-100"
          />
        </div>

        {idTabs === "pills-fulbrighter" ? (
          <div className="w-full">
            <DatePicker
              dateFormat="yyyy"
              showYearPicker
              selected={startDate}
              onChange={(date) => {
                setStartDate(date);
              }}
              className="form-control select-annuaire input-annuaire"
              placeholderText={t("year")}
            />
          </div>
        ) : null}
      </div>
    );
  };

  const FilterBlocMobile = () => {
    return (
      <div>
        <div className="w-100 pb-3">
          <CountrySelect
            value={val}
            onChange={changeHandler}
            searchPartner
            placeholder={t("select_country")}
            size="sm"
            className="w-100"
          />
        </div>

        {idTabs === "pills-fulbrighter" ? (
          <div className="w-full">
            <DatePicker
              dateFormat="yyyy"
              showYearPicker
              selected={startDate}
              onChange={(date) => {
                setStartDate(date);
              }}
              className="form-control select-annuaire input-annuaire"
              placeholderText={t("year")}
            />
          </div>
        ) : null}
      </div>
    );
  };

  if (devise) {
    return (
      <div>
        <div className="component-front-home">
          <NavMenuMobile />
          <div className="content-view-start view-start-mobile pb-5">
            <div className="bloc-banner2">
              <div className="py-3 text-center">
                <h1 className="titre-banner-annuaire my-2">{t("annuaire")}</h1>
              </div>
              <div className="container-warc" id="nav-annuaire-fulbrighter">
                <ul
                  className="nav nav-pills mb-3"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <a
                      className={`nav-link nav-tabs-annuaire ml-0 ${
                        idTabs === "pills-fulbrighter" ? "active" : ""
                      }`}
                      id="pills-fulbrighter-tab"
                      data-toggle="pill"
                      href="#pills-fulbrighter"
                      onClick={() => {
                        history.push("/app/annuaire/pills-fulbrighter");
                      }}
                      role="tab"
                      aria-controls="pills-fulbrighter"
                      aria-selected={
                        idTabs === "pills-fulbrighter" ? true : false
                      }
                    >
                      {t("fulbrighter")}
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      // className="nav-link nav-tabs-annuaire"
                      className={`nav-link nav-tabs-annuaire ${
                        idTabs === "pills-experts-personnes-ressources"
                          ? "active"
                          : ""
                      }`}
                      id="pills-experts-personnes-ressources-tab"
                      data-toggle="pill"
                      href="#pills-experts-personnes-ressources"
                      onClick={() => {
                        // setPays("");
                        // setStartDate("");
                        // setSearchTerm("");
                        // setVal("");
                        // setItemToShow(12);
                        // setExpand(true);
                        history.push(
                          "/app/annuaire/pills-experts-personnes-ressources"
                        );
                      }}
                      role="tab"
                      aria-controls="pills-experts-personnes-ressources"
                      aria-selected={
                        idTabs === "pills-experts-personnes-ressources"
                          ? true
                          : false
                      }
                    >
                      {t("experts_resource")}
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      // className=""
                      className={`nav-link nav-tabs-annuaire ${
                        idTabs === "pills-partenaires" ? " active" : ""
                      }`}
                      id="pills-partenaires-tab"
                      data-toggle="pill"
                      href="#pills-partenaires"
                      onClick={() => {
                        history.push("/app/annuaire/pills-partenaires");
                      }}
                      role="tab"
                      aria-controls="pills-partenaires"
                      aria-selected="false"
                    >
                      {t("institution_partners")}
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      className={`nav-link nav-tabs-annuaire ${
                        idTabs === "pills-contacts-urgence" ? "active" : ""
                      }`}
                      id="pills-contacts-urgence-tab"
                      data-toggle="pill"
                      href="#pills-contacts-urgence"
                      onClick={() => {
                        history.push("/app/annuaire/pills-contacts-urgence");
                      }}
                      role="tab"
                      aria-controls="pills-contacts-urgence"
                      aria-selected={
                        idTabs === "pills-contacts-urgence" ? true : false
                      }
                    >
                      {t("emergency_contacts")}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="container-warc my-5">
              <div className="tab-content" id="pills-tabContent">
                <div
                  className={`tab-pane fade mb-5 ${
                    idTabs === "pills-fulbrighter" ? " show active" : ""
                  }`}
                  id="pills-fulbrighter"
                  role="tabpanel"
                  aria-labelledby="pills-fulbrighter-tab"
                >
                  <div className="mb-4">
                    <div className="">
                      <div className="row align-items-center">
                        <div className="col-sm-12 pb-3">
                          <div className="input-group">
                            <input
                              type="search"
                              className="form-control input-annuaire pl-3"
                              placeholder={t("find_fulbrighter")}
                              aria-label="Recipient's username"
                              aria-describedby="basic-addon-annuaire"
                              onChange={handleChange}
                              value={searchTerm}
                            />
                            <span
                              className="input-group-text"
                              id="basic-addon-annuaire"
                            >
                              <IconlyPack.Search
                                set="light"
                                primaryColor="#000"
                              />
                            </span>
                          </div>
                        </div>
                        <div className="col-sm-12 pb-3">
                          <FilterBlocMobile />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    {fulbrighters?.isLoading && <CardSkeleton />}
                    {!fulbrighters?.isLoading &&
                      (fulbrighters?.filteredData?.filter(
                        (u) => u?.id !== user?.data?.id && u?.is_active === true
                      )?.length ? (
                        fulbrighters?.filteredData
                          ?.filter(
                            (u) =>
                              u?.id !== user?.data?.id && u?.is_active === true
                          )
                          ?.slice(0, itemToShow)
                          ?.map((item) => (
                            <div
                              className="col-xl-3 col-lg-3 col-md-3 pb-4"
                              key={item?.id}
                            >
                              <AnnuaireItem item={item} />
                            </div>
                          ))
                      ) : (
                        <div className="gfa-rowy mt-5">
                          <div className="alert alert-warning gfa-warning">
                            {t("noData")}
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
                <div
                  // className="tab-pane fade"
                  className={`tab-pane fade ${
                    idTabs === "pills-experts-personnes-ressources"
                      ? " show active"
                      : ""
                  }`}
                  id="pills-experts-personnes-ressources"
                  role="tabpanel"
                  aria-labelledby="pills-experts-personnes-ressources-tab"
                >
                  <div className="mb-4">
                    <div className="">
                      <div className="row align-items-center">
                        <div className="col-sm-12 pb-3">
                          <div className="input-group">
                            <input
                              type="search"
                              className="form-control input-annuaire pl-3"
                              placeholder={t("find_expert")}
                              aria-label="Recipient's username"
                              aria-describedby="basic-addon-annuaire"
                              onChange={handleChange}
                              value={searchTerm}
                            />
                            <span
                              className="input-group-text"
                              id="basic-addon-annuaire"
                            >
                              <IconlyPack.Search
                                set="light"
                                primaryColor="#000"
                              />
                            </span>
                          </div>
                        </div>
                        <div className="col-sm-12 pb-3">
                          <FilterBlocMobile />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    {experts?.isLoading && <CardSkeleton />}
                    {!experts?.isLoading &&
                      (experts?.filteredData?.filter(
                        (u) => u?.id !== user?.data?.id
                      )?.length ? (
                        experts?.filteredData
                          ?.slice(0, itemToShow)
                          ?.map((item) => (
                            <div
                              className="col-xl-3 col-lg-3 col-md-3 pb-4"
                              key={item?.id}
                            >
                              <ExpertPersonnesRessources item={item} />
                            </div>
                          ))
                      ) : (
                        <div className="gfa-rowy mt-5">
                          <div className="alert alert-warning gfa-warning">
                            {t("noData")}
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
                <div
                  className={`tab-pane fade ${
                    idTabs === "pills-partenaires" ? " show active" : ""
                  }`}
                  id="pills-partenaires"
                  role="tabpanel"
                  aria-labelledby="pills-partenaires-tab"
                >
                  <div className="mb-4">
                    <div className="">
                      <div className="row align-items-center">
                        <div className="col-sm-12 pb-3">
                          <div className="input-group">
                            <input
                              type="search"
                              className="form-control input-annuaire pl-3"
                              placeholder={t("find_partner")}
                              aria-label="Recipient's username"
                              aria-describedby="basic-addon-annuaire"
                              onChange={handleChange}
                              value={searchTerm}
                            />
                            <span
                              className="input-group-text"
                              id="basic-addon-annuaire"
                            >
                              <IconlyPack.Search
                                set="light"
                                primaryColor="#000"
                              />
                            </span>
                          </div>
                        </div>
                        <div className="col-sm-12 pb-3">
                          <FilterBlocMobile />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    {/* <div className="col-xl-3 col-lg-3 col-md-3 pb-4">
                  <InstitutionEtPartenaire />
                </div> */}
                    {partners?.isLoading && <CardSkeleton />}
                    {!partners?.isLoading &&
                      (partners?.filteredData?.filter(
                        (u) => u?.id !== user?.data?.id
                      )?.length ? (
                        partners?.filteredData
                          ?.slice(0, itemToShow)
                          ?.map((item) => (
                            <div
                              className="col-xl-3 col-lg-3 col-md-3 pb-4"
                              key={item?.id}
                            >
                              <InstitutionEtPartenaire item={item} />
                            </div>
                          ))
                      ) : (
                        <div className="gfa-rowy mt-5">
                          <div className="alert alert-warning gfa-warning">
                            {t("noData")}
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
                <div
                  className={`tab-pane fade ${
                    idTabs === "pills-contacts-urgence" ? "show active" : ""
                  }`}
                  id="pills-contacts-urgence"
                  role="tabpanel"
                  aria-labelledby="pills-contacts-urgence-tab"
                >
                  <div className="contacts-urgence-content">
                    {idTabs === "pills-contacts-urgence" && <ContactsUrgence />}
                  </div>
                </div>
              </div>
              {(idTabs === "pills-fulbrighter"
                ? fulbrighters?.filteredData?.filter(
                    (u) => u?.id !== user?.data?.id && u?.is_active === true
                  )?.length
                : idTabs === "pills-experts-personnes-ressources"
                ? experts?.filteredData?.length
                : partners?.filteredData?.length) > 9 ? (
                <div className="text-center pb-5">
                  <a
                    className="btn btn-participate-event trans-0-2 ml-auto"
                    onClick={() => showMore()}
                    href="#"
                  >
                    {expand ? (
                      <span>{t("see_less")}</span>
                    ) : (
                      <span>{t("see_more")}</span>
                    )}
                  </a>
                </div>
              ) : null}
            </div>
          </div>
          <FrontFooter />
        </div>
      </div>
    );
  }
  return (
    <div className="component-front-home">
      <FrontHeader />
      <div className="content-view-start pb-5">
        <div className="banner-annuaire bloc-banner2">
          <div className="text-center">
            <h1
              className="titre-banner-annuaire my-5"
              style={{ color: "#003DA5" }}
            >
              {t("annuaire")}
            </h1>
          </div>
          <div className="container-warc" id="nav-annuaire-fulbrighter">
            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
              <li className="nav-item" role="presentation">
                <a
                  className={`nav-link nav-tabs-annuaire ml-0 ${
                    idTabs === "pills-fulbrighter" ? "active" : ""
                  }`}
                  id="pills-fulbrighter-tab"
                  data-toggle="pill"
                  href="#pills-fulbrighter"
                  onClick={() => {
                    // setPays("");
                    // setStartDate("");
                    // setSearchTerm("");
                    // setVal("");
                    // setItemToShow(12);
                    // setExpand(true);
                    history.push("/app/annuaire/pills-fulbrighter");
                  }}
                  role="tab"
                  aria-controls="pills-fulbrighter"
                  aria-selected={idTabs === "pills-fulbrighter" ? true : false}
                >
                  {t("fulbrighter")}
                </a>
              </li>
              <li className="nav-item" role="presentation">
                <a
                  // className="nav-link nav-tabs-annuaire"
                  className={`nav-link nav-tabs-annuaire ${
                    idTabs === "pills-experts-personnes-ressources"
                      ? "active"
                      : ""
                  }`}
                  id="pills-experts-personnes-ressources-tab"
                  data-toggle="pill"
                  href="#pills-experts-personnes-ressources"
                  onClick={() => {
                    // setPays("");
                    // setStartDate("");
                    // setSearchTerm("");
                    // setVal("");
                    // setItemToShow(12);
                    // setExpand(true);
                    history.push(
                      "/app/annuaire/pills-experts-personnes-ressources"
                    );
                  }}
                  role="tab"
                  aria-controls="pills-experts-personnes-ressources"
                  aria-selected={
                    idTabs === "pills-experts-personnes-ressources"
                      ? true
                      : false
                  }
                >
                  {t("experts_resource")}
                </a>
              </li>
              <li className="nav-item" role="presentation">
                <a
                  // className=""
                  className={`nav-link nav-tabs-annuaire ${
                    idTabs === "pills-partenaires" ? " active" : ""
                  }`}
                  id="pills-partenaires-tab"
                  data-toggle="pill"
                  href="#pills-partenaires"
                  onClick={() => {
                    // setPays("");
                    // setStartDate("");
                    // setSearchTerm("");
                    // setVal("");
                    // setItemToShow(12);
                    // setExpand(true);
                    history.push("/app/annuaire/pills-partenaires");
                  }}
                  role="tab"
                  aria-controls="pills-partenaires"
                  aria-selected="false"
                >
                  {t("institution_partners")}
                </a>
              </li>
              <li className="nav-item" role="presentation">
                <a
                  className={`nav-link nav-tabs-annuaire ${
                    idTabs === "pills-contacts-urgence" ? "active" : ""
                  }`}
                  id="pills-contacts-urgence-tab"
                  data-toggle="pill"
                  href="#pills-contacts-urgence"
                  onClick={() => {
                    history.push("/app/annuaire/pills-contacts-urgence");
                  }}
                  role="tab"
                  aria-controls="pills-contacts-urgence"
                  aria-selected={
                    idTabs === "pills-contacts-urgence" ? true : false
                  }
                >
                  {t("emergency_contacts")}
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="container-warc pb-5 pt-3">
          <div className="tab-content" id="pills-tabContent">
            <div
              className={`tab-pane fade ${
                idTabs === "pills-fulbrighter" ? " show active" : ""
              }`}
              id="pills-fulbrighter"
              role="tabpanel"
              aria-labelledby="pills-fulbrighter-tab"
            >
              <div className="mb-4">
                <div className="">
                  <div className="row align-items-center">
                    <div className="col-xl-6 col-lg-6 col-md-6">
                      <div className="input-group">
                        <input
                          type="search"
                          className="form-control input-annuaire pl-3"
                          placeholder={t("find_fulbrighter")}
                          aria-label="Recipient's username"
                          aria-describedby="basic-addon-annuaire"
                          onChange={handleChange}
                          value={searchTerm}
                        />
                        <span
                          className="input-group-text"
                          id="basic-addon-annuaire"
                        >
                          <IconlyPack.Search set="light" primaryColor="#000" />
                        </span>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 d-flex-justify-content-end">
                      <FilterBloc />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                {fulbrighters?.isLoading && <CardSkeleton />}
                {!fulbrighters?.isLoading &&
                  (fulbrighters?.filteredData?.filter(
                    (u) => u?.id !== user?.data?.id && u?.is_active === true
                  )?.length ? (
                    fulbrighters?.filteredData
                      ?.filter(
                        (u) => u?.id !== user?.data?.id && u?.is_active === true
                      )
                      ?.slice(0, itemToShow)
                      ?.map((item) => (
                        <div
                          className="col-xl-3 col-lg-3 col-md-3 pb-4"
                          key={item?.id}
                        >
                          <AnnuaireItem item={item} />
                        </div>
                      ))
                  ) : (
                    <div className="gfa-rowy mt-5">
                      <div className="alert alert-warning gfa-warning">
                        {t("noData")}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            <div
              // className="tab-pane fade"
              className={`tab-pane fade ${
                idTabs === "pills-experts-personnes-ressources"
                  ? " show active"
                  : ""
              }`}
              id="pills-experts-personnes-ressources"
              role="tabpanel"
              aria-labelledby="pills-experts-personnes-ressources-tab"
            >
              <div className="mb-4">
                <div className="">
                  <div className="row align-items-center">
                    <div className="col-xl-6 col-lg-6 col-md-6">
                      <div className="input-group">
                        <input
                          type="search"
                          className="form-control input-annuaire pl-3"
                          placeholder={t("find_expert")}
                          aria-label="Recipient's username"
                          aria-describedby="basic-addon-annuaire"
                          onChange={handleChange}
                          value={searchTerm}
                        />
                        <span
                          className="input-group-text"
                          id="basic-addon-annuaire"
                        >
                          <IconlyPack.Search set="light" primaryColor="#000" />
                        </span>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 d-flex-justify-content-end">
                      <FilterBloc />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                {/* <div className="col-xl-3 col-lg-3 col-md-3 pb-4">
                  <ExpertPersonnesRessources />
                </div> */}
                {experts?.isLoading && <CardSkeleton />}
                {!experts?.isLoading &&
                  (experts?.filteredData?.filter(
                    (u) => u?.id !== user?.data?.id
                  )?.length ? (
                    experts?.filteredData?.slice(0, itemToShow)?.map((item) => (
                      <div
                        className="col-xl-3 col-lg-3 col-md-3 pb-4"
                        key={item?.id}
                      >
                        <ExpertPersonnesRessources item={item} />
                      </div>
                    ))
                  ) : (
                    <div className="gfa-rowy mt-5">
                      <div className="alert alert-warning gfa-warning">
                        {t("noData")}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            <div
              className={`tab-pane fade ${
                idTabs === "pills-partenaires" ? " show active" : ""
              }`}
              id="pills-partenaires"
              role="tabpanel"
              aria-labelledby="pills-partenaires-tab"
            >
              <div className="mb-4">
                <div className="">
                  <div className="row align-items-center">
                    <div className="col-xl-6 col-lg-6 col-md-6">
                      <div className="input-group">
                        <input
                          type="search"
                          className="form-control input-annuaire pl-3"
                          placeholder={t("find_partner")}
                          aria-label="Recipient's username"
                          aria-describedby="basic-addon-annuaire"
                          onChange={handleChange}
                          value={searchTerm}
                        />
                        <span
                          className="input-group-text"
                          id="basic-addon-annuaire"
                        >
                          <IconlyPack.Search set="light" primaryColor="#000" />
                        </span>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 d-flex-justify-content-end">
                      <FilterBloc />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                {/* <div className="col-xl-3 col-lg-3 col-md-3 pb-4">
                  <InstitutionEtPartenaire />
                </div> */}
                {partners?.isLoading && <CardSkeleton />}
                {!partners?.isLoading &&
                  (partners?.filteredData?.filter(
                    (u) => u?.id !== user?.data?.id
                  )?.length ? (
                    partners?.filteredData
                      ?.slice(0, itemToShow)
                      ?.map((item) => (
                        <div
                          className="col-xl-3 col-lg-3 col-md-3 pb-4"
                          key={item?.id}
                        >
                          <InstitutionEtPartenaire item={item} />
                        </div>
                      ))
                  ) : (
                    <div className="gfa-rowy mt-5">
                      <div className="alert alert-warning gfa-warning">
                        {t("noData")}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            <div
              className={`tab-pane fade ${
                idTabs === "pills-contacts-urgence" ? "show active" : ""
              }`}
              id="pills-contacts-urgence"
              role="tabpanel"
              aria-labelledby="pills-contacts-urgence-tab"
            >
              <div className="contacts-urgence-content">
                {idTabs === "pills-contacts-urgence" && <ContactsUrgence />}
              </div>
            </div>
          </div>
          {(idTabs === "pills-fulbrighter"
            ? fulbrighters?.filteredData?.filter(
                (u) => u?.id !== user?.data?.id && u?.is_active === true
              )?.length
            : idTabs === "pills-experts-personnes-ressources"
            ? experts?.filteredData?.length
            : partners?.filteredData?.length) > 9 ? (
            <div className="text-center my-5">
              <a
                className="btn btn-participate-event trans-0-2 ml-auto"
                onClick={() => showMore()}
              >
                {expand ? (
                  <span>{t("see_less")}</span>
                ) : (
                  <span>{t("see_more")}</span>
                )}
              </a>
            </div>
          ) : null}
        </div>
      </div>
      <FrontFooter />
    </div>
  );
};

export default Annuaire;

const CardSkeleton = () => {
  const renderModules = Array(12)
    .fill()
    .map((item, index) => (
      <Skeleton key={index} className="col-xl-3 col-lg-3 col-md-3 pb-4">
        <Skeleton className="details-annuaire-item">
          <Skeleton className="bloc-card pt-2">
            {/* <Skeleton className="d-flex justify-content-end mr-1">
              <Skeleton.Circle height={25} width={25} className="mb-2" />
            </Skeleton> */}
            <Skeleton className="d-flex justify-content-start">
              <Skeleton.Circle height={64} width={64} className="mb-3" />
              <Skeleton className="mb-3 w-100 m-2" width={`100%`}>
                <Skeleton.Rectangle
                  className="mb-1"
                  height={20}
                  width={`75%`}
                />
                <Skeleton.Rectangle
                  className="mb-1"
                  height={15}
                  width={`75%`}
                />
                <Skeleton.Rectangle
                  className="mb-1"
                  height={10}
                  width={`75%`}
                />
              </Skeleton>
            </Skeleton>
            <Skeleton className="d-flex justify-content-center mb-3">
              <Skeleton.Rectangle height={20} width={`25%`} className="m-2" />
              <Skeleton.Rectangle height={20} width={`25%`} className="m-2" />
              <Skeleton.Rectangle height={20} width={`25%`} className="m-2" />
            </Skeleton>
            <Skeleton className="d-flex justify-content-center mb-3">
              <Skeleton.Rectangle height={20} width={`25%`} className="m-2" />
              <Skeleton.Rectangle height={20} width={`25%`} className="m-2" />
              <Skeleton.Rectangle height={20} width={`25%`} className="m-2" />
            </Skeleton>

            <Skeleton className="d-flex justify-content-center">
              <Skeleton.Rectangle
                width={`30%`}
                height={20}
                className="mb-3 mr-1 ml-1"
              />
            </Skeleton>
          </Skeleton>
        </Skeleton>
      </Skeleton>
    ));
  return <React.Fragment>{renderModules}</React.Fragment>;
};
