import axios from "axios";
import Swal from "sweetalert2";
import baseUrl from "../../Http/backend-base-url";

export const FETCH_CONTACTS_REQUEST = "FETCH_CONTACTS_REQUEST";
export const FETCH_CONTACTS_SUCCESS = "FETCH_CONTACTS_SUCCESS";
export const FETCH_CONTACTS_FAILURE = "FETCH_CONTACTS_FAILURE";
export const ADD_CONTACT_SUCCESS = "ADD_CONTACT_SUCCESS";
export const UPDATE_CONTACT_SUCCESS = "UPDATE_CONTACT_SUCCESS";
export const DELETE_CONTACT_SUCCESS = "DELETE_CONTACT_SUCCESS";

// Définition de l'endpoint correct pour les contacts d'urgence
const EMERGENCY_CONTACTS_ENDPOINT = "/api/urgences/";

export const fetchContactsRequest = () => ({
  type: FETCH_CONTACTS_REQUEST,
});

export const fetchContactsSuccess = (contacts) => ({
  type: FETCH_CONTACTS_SUCCESS,
  payload: contacts,
});

export const fetchContactsFailure = (error) => ({
  type: FETCH_CONTACTS_FAILURE,
  payload: error,
});

export const addContactSuccess = (contact) => ({
  type: ADD_CONTACT_SUCCESS,
  payload: contact,
});

export const updateContactSuccess = (id, contact) => ({
  type: UPDATE_CONTACT_SUCCESS,
  payload: { id, contact },
});

export const deleteContactSuccess = (id) => ({
  type: DELETE_CONTACT_SUCCESS,
  payload: id,
});

export const fetchContacts = () => {
  return (dispatch) => {
    dispatch(fetchContactsRequest());
    const token = window.sessionStorage.getItem("userToken");

    console.log("Token utilisé pour fetchContacts:", token);

    const url = baseUrl + EMERGENCY_CONTACTS_ENDPOINT;

    const config = token
      ? { headers: { Authorization: `Bearer ${token}` } }
      : {};

    return axios
      .get(url, config)
      .then((response) => {
        console.log("Réponse API fetchContacts:", response.data);

        const transformedData = (response.data.results || response.data).map(
          (contact) => ({
            id: contact.id,
            nom: contact.full_name,
            email: contact.email,
            telephone: contact.phone,
            created_at: contact.created_at,
          })
        );

        dispatch(fetchContactsSuccess(transformedData));
        return transformedData;
      })
      .catch((error) => {
        console.error("Erreur fetchContacts:", error.response || error);
        dispatch(fetchContactsFailure(error.message));

        if (error.response && error.response.status === 401) {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "Session expirée",
            text: "Veuillez vous reconnecter pour continuer",
            showConfirmButton: true,
          });
        }
      });
  };
};

export const addContact = (contactData) => {
  return (dispatch) => {
    // Récupérer le token d'authentification
    let token = window.sessionStorage.getItem("userToken");

    // Vérifier si le token existe
    if (!token) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Vous n'êtes pas authentifié",
        text: "Veuillez vous connecter pour effectuer cette action",
        showConfirmButton: false,
        timer: 2000,
      });
      return Promise.reject(new Error("Non authentifié"));
    }

    const url = baseUrl + EMERGENCY_CONTACTS_ENDPOINT;

    // Format attendu par l'API
    const transformedData = {
      full_name: contactData.nom,
      email: contactData.email,
      phone: contactData.telephone,
    };

    // Afficher un console.log pour déboguer
    console.log("Envoi des données:", transformedData);
    console.log("Token utilisé:", token);
    console.log("URL de l'API:", url);

    return axios
      .post(url, transformedData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log("Réponse de l'API:", response.data);

        const transformedResponse = {
          id: response.data.id,
          nom: response.data.full_name,
          email: response.data.email,
          telephone: response.data.phone,
          created_at: response.data.created_at,
        };

        dispatch(addContactSuccess(transformedResponse));

        // Rafraîchir la liste complète des contacts pour s'assurer que tout est à jour
        dispatch(fetchContacts());

        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Contact ajouté avec succès",
          showConfirmButton: false,
          timer: 1500,
        });
        return transformedResponse;
      })
      .catch((error) => {
        console.error(
          "Erreur lors de l'ajout du contact:",
          error.response || error
        );

        // Afficher les détails de l'erreur pour le débogage
        if (error.response) {
          console.error("Données d'erreur:", error.response.data);
          console.error("Statut d'erreur:", error.response.status);
          console.error("Headers d'erreur:", error.response.headers);
        }

        // Gérer spécifiquement l'erreur 400
        if (error.response && error.response.status === 400) {
          let errorMessage = "Données invalides";

          // Vérifier s'il y a des messages d'erreur spécifiques
          if (error.response.data) {
            errorMessage = Object.entries(error.response.data)
              .map(([field, messages]) => `${field}: ${messages.join(", ")}`)
              .join("\n");
          }

          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "Erreur lors de l'ajout du contact",
            text: errorMessage,
            showConfirmButton: false,
            timer: 3000,
          });
        } else if (error.response && error.response.status === 401) {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "Session expirée",
            text: "Veuillez vous reconnecter pour continuer",
            showConfirmButton: true,
          });
        } else {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "Erreur lors de l'ajout du contact",
            text: error.message || "Une erreur est survenue",
            showConfirmButton: false,
            timer: 1500,
          });
        }
        throw error;
      });
  };
};

export const updateContact = (id, contactData) => {
  return (dispatch) => {
    let token = window.sessionStorage.getItem("userToken");

    // Vérifier si le token existe
    if (!token) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Vous n'êtes pas authentifié",
        text: "Veuillez vous connecter pour effectuer cette action",
        showConfirmButton: false,
        timer: 2000,
      });
      return Promise.reject(new Error("Non authentifié"));
    }

    const url = baseUrl + EMERGENCY_CONTACTS_ENDPOINT + id + "/";

    const transformedData = {
      full_name: contactData.nom,
      email: contactData.email,
      phone: contactData.telephone,
    };

    // Déboguer
    console.log("Mise à jour des données:", transformedData);
    console.log("URL de l'API:", url);

    return axios
      .put(url, transformedData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const transformedResponse = {
          id: response.data.id,
          nom: response.data.full_name,
          email: response.data.email,
          telephone: response.data.phone,
          created_at: response.data.created_at,
        };

        dispatch(updateContactSuccess(id, transformedResponse));

        // Rafraîchir la liste complète des contacts
        dispatch(fetchContacts());

        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Contact modifié avec succès",
          showConfirmButton: false,
          timer: 1500,
        });
        return transformedResponse;
      })
      .catch((error) => {
        console.error(
          "Erreur lors de la modification du contact:",
          error.response || error
        );

        // Afficher les détails de l'erreur pour le débogage
        if (error.response) {
          console.error("Données d'erreur:", error.response.data);
        }

        // Gérer spécifiquement l'erreur 401
        if (error.response && error.response.status === 401) {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "Session expirée",
            text: "Veuillez vous reconnecter pour continuer",
            showConfirmButton: true,
          });
        } else if (error.response && error.response.status === 400) {
          let errorMessage = "Données invalides";

          // Vérifier s'il y a des messages d'erreur spécifiques
          if (error.response.data) {
            errorMessage = Object.entries(error.response.data)
              .map(([field, messages]) => `${field}: ${messages.join(", ")}`)
              .join("\n");
          }

          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "Erreur lors de la modification du contact",
            text: errorMessage,
            showConfirmButton: false,
            timer: 3000,
          });
        } else {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "Erreur lors de la modification du contact",
            showConfirmButton: false,
            timer: 1500,
          });
        }
        throw error;
      });
  };
};

export const deleteContact = (id) => {
  return (dispatch) => {
    let token = window.sessionStorage.getItem("userToken");

    // Vérifier si le token existe
    if (!token) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Vous n'êtes pas authentifié",
        text: "Veuillez vous connecter pour effectuer cette action",
        showConfirmButton: false,
        timer: 2000,
      });
      return Promise.reject(new Error("Non authentifié"));
    }

    const url = baseUrl + EMERGENCY_CONTACTS_ENDPOINT + id + "/";
    return axios
      .delete(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        dispatch(deleteContactSuccess(id));

        // Rafraîchir la liste complète des contacts
        dispatch(fetchContacts());

        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Contact supprimé avec succès",
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .catch((error) => {
        console.error(
          "Erreur lors de la suppression du contact:",
          error.response || error
        );

        // Gérer spécifiquement l'erreur 401
        if (error.response && error.response.status === 401) {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "Session expirée",
            text: "Veuillez vous reconnecter pour continuer",
            showConfirmButton: true,
          });
        } else {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "Erreur lors de la suppression du contact",
            showConfirmButton: false,
            timer: 1500,
          });
        }
        throw error;
      });
  };
};
